import { inject } from '@angular/core';
import { CrmOnceSubject } from 'common-module/core';
import { CrmModalService } from 'common-module/modal';
import {
  CrmTranslateMessage,
  CrmTranslateService,
} from 'common-module/translate';

export const deleteModalFn = () => {
  const modalService = inject(CrmModalService);
  const translate = inject(CrmTranslateService);

  return (title: CrmTranslateMessage) => {
    const subject = new CrmOnceSubject<void>();

    modalService.confirm({
      nzTitle: translate.get(title),
      nzOkDanger: true,
      nzOnOk: () => subject.next(),
      nzOnCancel: () => subject.complete(),
    });

    return subject.asObservable();
  };
};
